export interface ShippingSettings {
  freeWeightLimit: number;
  weightPrices: Array<WeightPrice>;
  priceRanges: Array<ShippingPriceRange>;
  deliveryDaysRanges: Record<string, deliveryDaysRange>;
  zoneWeightPrices: Array<ZoneWeightPrice>;
  shippingTypeRestrictions: any;
  zoneFreeCases: Array<ZoneFreeCase>;
  zoneShippingInfo: Array<ZoneShippingInfo>;
  available: Array<ShippingType>;
  extra_economic: number;
  extra_24: number;
  extra_24_48: number;
  extra_urgent: number;
}

/**
 * Precio del envío por rango de peso (caso general)
 * los dos primeros valores son el rango de peso (en kg) => [cerrado, abierto)
 * el tercer valor es el precio (en euros)
 * extra es el precio por Kg que se debe cobrar si excede el rango (en euros)
 */
interface WeightPrice {
  range: Array<rangePrice>;
  extra: number; // (en euros) precio por kilo extra si se está cobrando el envío
  freeExtra: 0.65,  // (en euros) precio por kilo extra si NO se está cobrando el envío, a partir de FREEWEIGHTLIMIT kilos
}

interface ShippingPriceRange {
  minLimit: number;
  maxLimit: number;
  price: number;
}

interface deliveryDaysRange {
  minDays: number;
  maxDays: number;
}

type rangePrice = [number, number, number] //low hight price

interface ZoneWeightPrice extends WeightPrice {
  zone: Array<string>; //postalcodes
}

interface ZoneFreeCase {
  zone: Array<string>,
  amount: number
}

interface ZoneShippingInfo {
  zone: Array<string>,
  info: string; //HTML
}

export enum ShippingType {
  ECONOMIC = 'economic',
  STANDARD = 'standard', // 24 h (+1€)
  STANDARD_24 = 'standard_24', // 24 h (+1€)
  URGENT = 'urgent', // next day 14 h (+4€)
  PICKUP = 'pickup'
}

