import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { OptionsButtonComponent } from "./options-button.component";
import { OptionTurnpageButtonComponent } from "../option-turnpage-button/option-turnpage-button.component";
import { CheckColorCoverComponent } from "../check-color-cover/check-color-cover.component";
import { CheckAutoturnComponent } from "../check-autoturn/check-autoturn.component";
import {
  TooltipModule,
  TooltipOptions,
} from "@cloudfactorydk/ng2-tooltip-directive";
import { DefaultTooltipOptions } from "../default-tooltip-options";
import { IonicModule } from "@ionic/angular";
import { CheckLaminatedCoverComponent } from "../check-laminated-cover/check-laminated-cover.component";
import { FormsModule } from "@angular/forms";
import { TooltipInfoColorComponent } from "../../home-new/tooltip-info-components/tooltip-info-color/tooltip-info-color.component";
import { TooltipInfoPageSizeComponent } from "../../home-new/tooltip-info-components/tooltip-info-page-size/tooltip-info-page-size.component";
import { TooltipInfoPaperWeightComponent } from "../../home-new/tooltip-info-components/tooltip-info-paper-weight/tooltip-info-paper-weight.component";
import { TooltipInfoTwoSidedComponent } from "../../home-new/tooltip-info-components/tooltip-info-two-sided/tooltip-info-two-sided.component";
import { TooltipInfoPageOrientationComponent } from "../../home-new/tooltip-info-components/tooltip-info-page-orientation/tooltip-info-page-orientation.component";
import { TooltipInfoPagesPerSheetComponent } from "../../home-new/tooltip-info-components/tooltip-info-pages-per-sheet/tooltip-info-pages-per-sheet.component";
import { TooltipInfoPageTurnComponent } from "../../home-new/tooltip-info-components/tooltip-info-page-turn/tooltip-info-page-turn.component";
import { BindingOptionsModule } from "../binding-options/binding-options.module";
import { TooltipInfoCoverLaminatedComponent } from "../../home-new/tooltip-info-components/tooltip-info-cover-laminated/tooltip-info-cover-laminated.component";
import { TooltipInfoFoldersComponent } from "../../home-new/tooltip-info-components/tooltip-info-folders/tooltip-info-folders.component";
import { TooltipInfoStaplingComponent } from "../../home-new/tooltip-info-components/tooltip-info-stapling/tooltip-info-stapling.component";
import { TooltipInfoBindingComponent } from "../../home-new/tooltip-info-components/tooltip-info-binding/tooltip-info-binding.component";
import { LaminationOptionsComponent } from "../../home-new/lamination-options/lamination-options.component";
import { TooltipInfoShippingFreeComponent } from "../../home-new/tooltip-info-components/tooltip-info-shipping-free/tooltip-info-shipping-free.component";

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [
    OptionsButtonComponent,
    OptionTurnpageButtonComponent,
    CheckColorCoverComponent,
    CheckAutoturnComponent,
    CheckLaminatedCoverComponent,
    TooltipInfoColorComponent,
    TooltipInfoPageSizeComponent,
    TooltipInfoPaperWeightComponent,
    TooltipInfoTwoSidedComponent,
    TooltipInfoPageOrientationComponent,
    TooltipInfoPagesPerSheetComponent,
    TooltipInfoPageTurnComponent,
    TooltipInfoCoverLaminatedComponent,
    TooltipInfoFoldersComponent,
    TooltipInfoStaplingComponent,
    TooltipInfoBindingComponent,
    TooltipInfoShippingFreeComponent,
  ],
  imports: [
    CommonModule,
    IonicModule,
    TooltipModule.forRoot(DefaultTooltipOptions as TooltipOptions),
    FormsModule,
    BindingOptionsModule,
    LaminationOptionsComponent,
  ],
  exports: [
    OptionsButtonComponent,
    OptionTurnpageButtonComponent,
    CheckColorCoverComponent,
    CheckAutoturnComponent,
    CheckLaminatedCoverComponent,
    TooltipInfoCoverLaminatedComponent,
    TooltipInfoFoldersComponent,
    TooltipInfoStaplingComponent,
    TooltipInfoShippingFreeComponent
  ],
})
export class OptionsButtonModule {}
