import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChange,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Coupon } from '../coupon.models';
import { CouponService } from '../coupon.service';
import { CartService } from "../../shared/cart.service";
import { DiscountType, ProductType } from "../../models/models";
import { OrderStateService } from 'src/app/shared/order-state.service';
import { Subject, takeUntil } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { OrderService } from 'src/app/shared/order.service';
import { WalletService } from "../../wallet/wallet.service";
import { ToastController } from "@ionic/angular";

export interface CouponResp extends Coupon {
  message: string,
  label: string;
}

@Component({
  selector: 'app-add-coupon',
  templateUrl: './add-coupon.component.html',
  styleUrls: ['./add-coupon.component.scss'],
})
export class AddCouponComponent implements OnInit, AfterViewInit, OnChanges {
  @ViewChild('inputCoupon', { static: false }) ionInput: any;
  @Output() applyCoupon = new EventEmitter<any>();
  @Input() orderForm: UntypedFormGroup;
  @Input() coupon;
  @Input() freeShipping: boolean = false;
  @Input() disabled: boolean;
  edit = true;

  cuponCode = '';
  errorMessage = '';
  titleCoupon = '';
  discountType = DiscountType;

  private componetDestroyed = new Subject();

  order = null;

  constructor(
    private couponService: CouponService,
    public cartService: CartService,
    public orderStateService: OrderStateService,
    private route: ActivatedRoute,
    private orderService: OrderService,
    private walletService: WalletService,
    private toastController: ToastController
  ) {
  }

  ngOnInit() {
    this.orderStateService.order$
      .pipe(takeUntil(this.componetDestroyed))
      .subscribe((order) => {
        this.order = order;
        //console.log('Order received:', this.order);
      });


  }

  ngOnChanges(changes: SimpleChanges) {
    const currentcoupon: SimpleChange = changes.coupon;
    if (currentcoupon) {
      this.titleCoupon = "";
      if (currentcoupon.currentValue) {
        this.cuponCode = currentcoupon.currentValue.code;
        this.titleCoupon = currentcoupon.currentValue.title;
      } else {
        this.cuponCode = null;
      }
    }
    // else{
    // if(this.coupon){
    //   this.cuponCode = this.coupon.code;
    // }
    //}

  }

  ngAfterViewInit(): void {
    // if(this.coupon){
    //   this.cuponCode = this.coupon.code;
    // }
  }

  async apply(value?) {
    // if (!this.cartService.shippingType) {
    //   const toast = await this.toastController.create({
    //     message: 'Debes seleccionar un tipo de envío antes de aplicar un cupón',
    //     duration: 4000,
    //     position: 'middle',
    //     color: 'danger',
    //     cssClass: 'fitWidthToast'
    //   });
    //   toast.present();
    //   return
    // }
    this.titleCoupon = "";
    //const { userId, email, ...rest } = this.orderForm.value;
    // Para no repetir orderFormGroup en CartPreview simplemente cogemos los
    // valores de userId y email de cartService.
    //const userId = this.cartService.order.userId; // Problema de cogerlo de cartService.order: si intentas aplicar cupon desde el CartPreview
    // sin haber accedido a CartPage, no se inicializa order y userId es null.

    //console.log('Applying coupon with order:', this.order);
    const email = this.order.email;
    this.couponService.validate({
      code: this.cuponCode.toUpperCase(),
      email,
      cartAmount: Number(this.cartService.cart.price.totalAmt - this.cartService.cart.shipping.price.amt).toFixed(2)
    })
      .then((resp: CouponResp) => {//resp:Coupon
        // console.log("resp Coupon", resp);
        this.edit = !resp.valid;
        if (!resp.valid) {

          this.errorMessage = resp.label;
          //resp.label
          resp = null;
        } else {
          this.edit = false;
          this.titleCoupon = resp.title;
          this.errorMessage = ''
        }
        // console.log("this.edit", this.edit);
        this.applyCoupon.emit(resp);
      })
      .catch(err => {
        // this.errorMessage = 'Error al procesar cupón';  // MN
        this.errorMessage = 'Debes escribir tu dirección de correo o iniciar sesión primero';
      })
  }

  remove() {
    this.edit = true;
    this.cuponCode = null
    this.titleCoupon = "";
    this.applyCoupon.emit(null);
    //
  }

  add() {
    this.edit = true;
    setTimeout(() => {
      this.ionInput.setFocus();
    }, 300);
  }

  onKeyPress(event) {
    // console.log("event", event);
    return true
    // return /^[0-9a-zA-Z]+$/.test(event.key)
    // console.log("coupon", this.cuponCode);

  }

  onInputChange() {
    const v = this.cuponCode.split('').map(c => /^[0-9a-zA-Z\u00f1\u00d1]$/.test(c) ? c : '').join('');
    setTimeout(() => {
      this.cuponCode = v
    }, 10)
  }

  public validate(code) {
    this.cuponCode = code?.toUpperCase();
    if (this.cuponCode) {
      this.apply();
    } else {
      this.applyCoupon.emit(null);
    }
  }

  getCannotUseCouponMsg() {
    const hasWalletBonus = this.cartService.cart.items.find(item => item.product.type === ProductType.WALLETBONUS);
    const hastPrintProduct = this.cartService.cart.items.find(item => item.product.type === ProductType.PRINT);
    const hasWallet = this.walletService.wallet.balance > 0 && this.cartService.discountWallet;

    if (!hastPrintProduct) {
      return 'Solo puedes aplicar cupones de descuento sobre impresiones'
    } else {
      return 'El uso de cupones no es compatible con el uso del bono monedero'
    }
  }
}
