import { Component, OnInit } from "@angular/core";
import { ShippingSettings } from "src/app/models/shipping-settings.model";
import { SettingService } from "src/app/shared/setting.service";

@Component({
  selector: "app-tooltip-info-shipping-free",
  templateUrl: "./tooltip-info-shipping-free.component.html",
  styleUrls: ["./tooltip-info-shipping-free.component.scss"],
})
export class TooltipInfoShippingFreeComponent implements OnInit {

  public standardShippingThreshold = 0; // Envio estándar en península
  public balearShippingThreshold = 0; // Envío estándar en Baleares
  public priorityShippingCost = 0; // Coste de envío prioritario

  public shippingSettings: ShippingSettings;

  constructor(private settingService: SettingService) {}

  ngOnInit() {
    this.loadDataFromSettings()
  }

  private loadDataFromSettings() {
    this.settingService
    .getShippingSettings()
    .then((shippingSettings) => {
      this.shippingSettings = shippingSettings;
      this.priorityShippingCost = shippingSettings.extra_24;
      this.standardShippingThreshold = shippingSettings.zoneFreeCases[3].amount;
      this.balearShippingThreshold = shippingSettings.zoneFreeCases[1].amount;
    })
    .catch((error) => {
      console.log(`Error getting shipping settings: ${error}`);
    });
  }
}
