import { Component, OnInit } from '@angular/core';
import { ModalController } from "@ionic/angular";

@Component({
  selector: 'app-incompatible-lamination-alert',
  templateUrl: './incompatible-lamination-alert.component.html',
  styleUrls: ['./incompatible-lamination-alert.component.scss'],
})
export class IncompatibleLaminationAlertComponent implements OnInit {

  constructor(public modalController: ModalController) { }

  ngOnInit() {}

}
